import { useEffect } from 'react';

const AdsComponent = (prop: { namePageClass: string }) => {
    const { namePageClass } = prop;
    useEffect(() => {
        // @ts-ignore
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, []);

    return (
        <div
            style={{
                width: '100%',
                marginTop: namePageClass === 'wait_page' ? '10rem' : 'auto',
                display:'flex',
                justifyContent:'center'
            }}
        >
            <ins
                className={`adsbygoogle ${namePageClass}`}
                style={{
                    display: 'block',
                }}
                data-ad-client="ca-pub-1847267298539084"
                data-ad-slot="4294627984"
            ></ins>
        </div>
    );
};

export default AdsComponent;