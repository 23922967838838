import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import {
    // MACHINE_TYPES,
    // NFC_IS_AVAILABLE,
    QR_SERVICES,
    STORAGE_KEYS,
    // TAB_SYNC_DURATION,
    isDev,
    isStage,
} from '../../../constants';
import { DEV_MACHINES } from '../../../settings';
import {
    feedbackState,
    orderState,
    qrState,
    vmState,
} from '../../../state/recoilState';
import { Feedback, Order, VendingMachine } from '../../../types';
import {
    cl,
    cltext,
    getOrderInfoById,
    getVmDetails,
    screenLog,
} from '../../../utils/general';
import { clearAllSavedStates, storeVMState } from '../../../utils/stateStorage';
import { trackClick, trackEvent, trackPageView } from '../../../utils/tracking';
import ContentBox from '../../common/ContentBox';
import Input from '../../common/Input';
import InputValidationMsg from '../../common/InputValidationMsg';
import PrimaryButton from '../../common/PrimaryButton';
import ScreenTitle from '../../common/ScreenTitle';
import ScreenWrapper from '../../common/ScreenWrapper';
import { checkQr } from './checkQr';
import AdsComponent from '../../AdSenseComponent';

export interface CheckQrProps {
    qr: string;
    onSuccess: (vm: VendingMachine) => void;
    onFailure: () => void;
    onNotSupported: (qr: string) => void;
    onNotConnected: (qr: string) => void;
    onFiscalService: (qr: string) => void;
    onMainService: (qr: string) => void;
    onsetVm: (vm: VendingMachine) => void;
    onFeedbacks: (qr: string, vm?: number) => void;
}

function geTranslate(language_code: string | undefined = undefined) {
    if (language_code) {
        i18n.changeLanguage(language_code === 'ge' ? 'ka' : language_code); // on machines Georgian lang marked as 'ge' while in browser it's 'ka'
    }
}

const checkQrService = (
    qr: string,
    onSetVm: (vm: VendingMachine) => void,
    onFailure: () => void,
    onNotSupported: (qr: string) => void,
    onMainService: (qr: string) => void,
    onFiscalService: (qr: string) => void,
    onNotConnected: (qr: string) => void,
    onSuccess: (vm: VendingMachine) => void,
    onFeedbacks: (qr: string, vm?: number) => void
) => {
    const url = isDev
        ? `/dev/qr.json?qr=${qr}&service=1}`
        : `/api/check?qr=${qr}`;

    async function fetchQRData(url: string): Promise<void> {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(response.statusText);
            }

            const result: VendingMachine = await response.json();
            // cl('QR data parsed', result);

            const {
                qr,
                // type_id,
                // allowed,
                language_code,
                QRservices,
                // feedbacks,
                // fisc_receipts,
            } = result;
            onSetVm(result);

            // TODO remove in prod - this is for QA only
            if (!isDev && isStage && !DEV_MACHINES.includes(qr)) {
                cl('Not supported VM');
                onNotSupported(qr);
                return;
            }

            const {
                isCT5,
                isConnected,
                isAllowed,
                isFridge,
                isFridgeSmart,
                isFeedbackEnabled,
                hasFiscalReceipt,
            } = getVmDetails(result); // ct_type === CT_TYPES.CT5;

            geTranslate(language_code);
            // standart function to go to payment page
            // eslint-disable-next-line no-inner-declarations
            function checkOrder() {
                if ((!isAllowed && !isFridge) || isFridgeSmart) {
                    cl('Not supported VM');
                    trackEvent('not-supported', { qr });
                    onNotSupported(qr);
                } else if (!isConnected && isCT5) {
                    cl('Not connected AND CT5');
                    trackEvent('not-connected', { qr });
                    onNotConnected(qr);
                } else {
                    geTranslate();
                    onSuccess(result);
                }
            }

            const isAllowedOrFridge = isAllowed || isFridge;
            // function for SEPARATED services
            // TODO extract this outside
            // eslint-disable-next-line no-inner-declarations
            function checkFeedbacksWhenSeparatedServices() {
                if (isFeedbackEnabled && hasFiscalReceipt) {
                    return onMainService(qr);
                }
                if (isFeedbackEnabled) {
                    return onFeedbacks(qr, result.vm_id);
                }
                if (hasFiscalReceipt) {
                    return onFiscalService(qr);
                }
                return onNotSupported(qr);

                // old version
                // isFeedbackEnabled
                //     ? hasFiscalReceipt
                //         ? onMainService(qr)
                //         : onFeedbacks(qr)
                //     : hasFiscalReceipt
                //     ? onFiscalService(qr)
                //     : onNotSupported(qr);
            }

            // check fiscal param when feedbacks === 1 for ALL PERMITTED SERVICES
            // TODO extract this outside
            // eslint-disable-next-line no-inner-declarations
            function checkFiscalWhenFeedbacksTrue() {
                if (hasFiscalReceipt || isAllowedOrFridge) {
                    return onMainService(qr);
                }
                return onFeedbacks(qr, result.vm_id);

                // hasFiscalReceipt
                //     ? onMainService(qr)
                //     : isAllowedOrFridge
                //     ? onMainService(qr)
                //     : onFeedbacks(qr);
            }

            // check fiscal param when feedbacks === 0 ALL PERMITTED SERVICES
            // TODO extract this outside
            // eslint-disable-next-line no-inner-declarations
            function checkFiscalWhenFeedbacksFalse() {
                hasFiscalReceipt
                    ? isAllowedOrFridge
                        ? onMainService(qr)
                        : onFiscalService(qr)
                    : isAllowedOrFridge
                    ? checkOrder()
                    : onNotSupported(qr);
            }

            switch (QRservices) {
                case QR_SERVICES.SEPARATED_SERVICES:
                    checkFeedbacksWhenSeparatedServices();
                    break;
                case QR_SERVICES.ALL_IS_PERMITTED:
                    isFeedbackEnabled
                        ? checkFiscalWhenFeedbacksTrue()
                        : checkFiscalWhenFeedbacksFalse();
                    break;
                default:
                    onNotSupported(qr);
            }
        } catch (error) {
            console.error(error);
            onFailure();
        }
    }

    fetchQRData(url);
};

const ScanQrCode = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [urlParams] = useState(new URLSearchParams(window.location.search));
    const [urlQr] = useState(urlParams.get('qr'));
    // const [urlNfc, setUrlNfc] = useState(urlParams.get('nfc'));
    const [urlOrder, setUrlOrder] = useState(urlParams.get('oid'));
    const [urlService, setUrlService] = useState(urlParams.get('service'));

    const [vm, setVm] = useRecoilState(vmState);
    const [qrCodeStr, setQrCodeStr] = useRecoilState(qrState);
    const [order, setOrder] = useRecoilState(orderState);

    const [error, setError] = useState({ qrValidation: '' }); // TODO - rise to App level
    // const [isNFC, setIsNFC] = useState(urlNfc === NFC_IS_AVAILABLE);
    const [feedback, setFeedback] = useRecoilState<Feedback>(feedbackState);

    // const [noCheck, setNoCheck] = useState(false);

    // qrCheck - unsupported VM
    const handleUnsupportedVM = (qr: string = qrCodeStr) => {
        cltext('handleUnsupportedVM:' + qr, 'orange');
        clearAllSavedStates();
        navigate(`/${qr}/notsupported`, { replace: true });
    };
    // qrCheck - not connected VM
    const handleDisconnectedVM = (qr: string = qrCodeStr) => {
        navigate(`/${qr}/notconnected`, { replace: true });
    };
    const qrCheckFailHandler = () => {
        clearAllSavedStates();
        setError({ qrValidation: 'Invalid code' });
    };
    // qrCheck - success
    const qrCheckSuccessHandler = (vmData: VendingMachine) => {
        setVm(vmData);
        navigate(`/${vmData.qr}/connecting`, { replace: true });
    };
    // servicePage
    const setData = (vmData: VendingMachine) => {
        cltext('setData', 'greenyellow');
        cl('setData', vmData);

        setVm(vmData);
        // storeVMState(vmData);
    };

    const goToMainService = (qr: string = qrCodeStr) => {
        navigate(`/${qr}/servicemain`, { replace: true });
    };
    const goToRobotPage = (qr: string = qrCodeStr) => {
        navigate(`/${qr}/servicerobot`, { replace: true });
    };
    const goToFeedbackPage = (qr: string = qrCodeStr, vm?: number) => {
        vm && createFeedback(vm);
        navigate(`/${qr}/smilepage`, { replace: true });
    };

    //-----------------create Feedback------------//
    async function createFeedback(vm: number): Promise<void> {
        //TODO - extract to constants
        const url = isDev
            ? '/dev/feedback_mock.json'
            : `/api/get_feedback_data?vm=${vm}`;
        const orderInfo = {
            wallet_user_id: order.user_id,
            transaction_id: order.id,
        };
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(orderInfo),
            });
            if (response.status === 200) {
                const result: Feedback = await response.json();
                setFeedback(result);
            } else {
                console.warn('Something went wrong - 294');
                throw new Error(response.statusText);
            }
        } catch (error) {
            console.error('Request failed:', error);
            Sentry.captureException(error);
        }
    }

    useEffect(() => {
        trackPageView('scan-qr', { urlParams, qrCodeStr, vm, order });
        screenLog('Scan QR');
    }, []);

    // existing logic
    // if (urlOrder) and !order (order.id?) then set as processing payment and fetch order
    // else if urlQr and it differs from qr in the store - then set store qr and check it
    useEffect(() => {
        console.log('VM', vm);
        cltext(
            `ScanQrCode useEffect 2 \nurlQr:${urlQr}\n vmQr: ${vm.qr} \n urlOrder: ${urlOrder}`,
            'greenyellow;font-weight:bold;'
        );

        // order exists
        if (urlOrder && !order.id) {
            cl('kmg 280, order', order, urlOrder);
            // 1. get order details
            // 2. set order
            // 3. navigate waiting screen
            getOrderInfoById(
                urlOrder,
                (order: Order) => {
                    setOrder(order);
                    localStorage.setItem(
                        STORAGE_KEYS.ORDER_STATE,
                        JSON.stringify(order)
                    );
                    localStorage.setItem(
                        STORAGE_KEYS.ORDER_SYNC_TIMESTAMP,
                        Date.now().toString()
                    );
                    trackEvent('scan-qr -> order', { order_id: order.id });
                    navigate(`/${order.id}/wait`, { replace: true });
                },
                () => {
                    trackEvent('scan-qr -> get-order-failed', {
                        order_id: urlOrder,
                    });
                    navigate(`/${order.id}/failed`, { replace: true }); // TODO extract PATHs to constants
                }
            );
        } else if (urlQr && urlQr !== vm.qr) {
            cltext('new QR', 'greenyellow; font-weight:bold;');
            setQrCodeStr(urlQr);

            if (urlService) {
                cltext(`urlService - ${urlService}`, 'greenyellow');
                checkQrService(
                    urlQr,
                    setData,
                    qrCheckFailHandler,
                    handleUnsupportedVM,
                    goToMainService,
                    goToRobotPage,
                    handleDisconnectedVM,
                    qrCheckSuccessHandler,
                    goToFeedbackPage
                );
            } else {
                checkQr({
                    qr: urlQr,
                    onSuccess: qrCheckSuccessHandler,
                    onFailure: qrCheckFailHandler,
                    onNotSupported: handleUnsupportedVM,
                    onNotConnected: handleDisconnectedVM,
                    onFiscalService: goToRobotPage,
                    onMainService: goToMainService,
                    onsetVm: setData,
                    onFeedbacks: goToFeedbackPage,
                });
            }
        } else {
            cltext('346 no check in ScanQr', 'red;font-weight:bold;');
            cl('VM', vm);
            cl('is service', urlService);
            const { qr, isConnected, isAllowed } = getVmDetails(vm);
            if (
                urlQr &&
                urlQr === qr &&
                isConnected &&
                isAllowed &&
                !urlService
            ) {
                // we are on the same VM
                qrCheckSuccessHandler(vm);
            }
            //     // reset all?
            //     // if (qrCodeStr !== '') {
            //     //   setQrCodeStr('');
            //     // }
            //     // if(vm.vm_id) { setVm({} as VendingMachine); }
            //     // if(order.id) { setOrder({} as Order);}
        }
    }, [vm, order]);

    const submitButtonHandler = () => {
        trackClick('scan-qr-submit', { qr: qrCodeStr });
        if (!qrCodeStr || qrCodeStr?.length === 0) {
            setError({ qrValidation: t('Enter code') });
        } else {
            setError({ qrValidation: '' });
            checkQr({
                qr: qrCodeStr,
                onSuccess: qrCheckSuccessHandler,
                onFailure: qrCheckFailHandler,
                onNotSupported: handleUnsupportedVM,
                onNotConnected: handleDisconnectedVM,
                onFiscalService: goToRobotPage,
                onMainService: goToMainService,
                onsetVm: setData,
                onFeedbacks: goToFeedbackPage,
            });
        }
    };
    const formSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        submitButtonHandler();
    };

    return (
        <ScreenWrapper>
            <ScreenTitle>{t('Scan QR')}</ScreenTitle>
            <ContentBox>
                <form onSubmit={formSubmitHandler}>
                    <Input
                        type='text'
                        // inputMode="numeric"
                        placeholder={t<string>('Enter QR')}
                        onChange={(e) => setQrCodeStr(e.target.value)}
                        value={qrCodeStr}
                    />
                    <InputValidationMsg>
                        {t(error?.qrValidation ?? 'error')}
                    </InputValidationMsg>
                    <PrimaryButton type='button' onClick={submitButtonHandler}>
                        {t('OK')}
                    </PrimaryButton>
                </form>
            </ContentBox>
        </ScreenWrapper>
    );
};

export default ScanQrCode;
