import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { trackPageView } from '../../../utils/tracking';

import { useRecoilValue } from 'recoil';
import { orderState, vmState } from '../../../state/recoilState';
import { BasicScreenProps } from '../../../types';
import { screenLog } from '../../../utils/general';
import { Button, SmallButton, SmallPrimaryButton } from '../../common/Button';
import ButtonBox from '../../common/ButtonBox';
import ContentBox from '../../common/ContentBox';
import PrimaryButton from '../../common/PrimaryButton';
import ScreenTitle from '../../common/ScreenTitle';
import ScreenWrapper from '../../common/ScreenWrapper';
import AdsComponent from '../../AdSenseComponent';

interface CompleteScreenProps {
    onRepeatPurchase: () => void;
    onFeedback?: () => void;
    showFeedback?: boolean;
    onGetReceipt: () => void;
    showReceipt?: boolean;
}

const CanvasObject = styled.object`
    margin: -1vmax 0 0 0;
    width: min(90vw, 60vh);
    height: 30vh;
`;

const CompletedScreen = (props: CompleteScreenProps) => {
    const {
        onRepeatPurchase,
        onFeedback,
        showFeedback,
        onGetReceipt,
        showReceipt,
    } = props;
    const { t } = useTranslation();
    const order = useRecoilValue(orderState);
    const vmInfo = useRecoilValue(vmState);
    const isAds = vmInfo.ads && JSON.parse(vmInfo.ads);

    useEffect(() => {
        trackPageView('completed', { orderId: order.id, ...order });
        screenLog('Completed screen');
    }, []);

    return (
        <ScreenWrapper>
            <ScreenTitle>{t('Thank you')}</ScreenTitle>
            {isAds?.success === 1 && <AdsComponent namePageClass="succsess_page" />}
            <ContentBox>
                {isAds?.success !=1 && <CanvasObject data="/tick.html" />}
                <ButtonBox>
                    {showFeedback && (
                        <PrimaryButton type="button" onClick={onFeedback}>
                            {t('Feedback')}
                        </PrimaryButton>
                    )}

                    {showReceipt && !showFeedback ? (
                        <Button onClick={onGetReceipt}>
                            {t('Get fiscal receipt')}
                        </Button>
                    ) : (
                        showReceipt && (
                            <SmallButton
                                onClick={onGetReceipt}
                                style={{ marginRight: '2px' }}
                            >
                                {t('Get fiscal receipt')}
                            </SmallButton>
                        )
                    )}

                    {showReceipt && showFeedback ? (
                        <SmallButton
                            onClick={onRepeatPurchase}
                            style={{ marginLeft: '2px' }}
                        >
                            {t('Repeat_purchase')}
                        </SmallButton>
                    ) : (
                        <Button onClick={onRepeatPurchase}>
                            {t('Repeat_purchase')}
                        </Button>
                    )}
                </ButtonBox>
            </ContentBox>
        </ScreenWrapper>
    );
};

export default CompletedScreen;
