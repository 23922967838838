import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { API, DEVICE_STATUS, ORDER_STATUS, isDev } from '../../../constants';
import useTimer from '../../../hooks/useTimer';
import { WAIT_TIMER } from '../../../screensSettings';
import { orderState, vmState } from '../../../state/recoilState';
import {
    BasicScreenProps,
    Order,
    ScreenWithTimerProps,
    VendingMachine,
} from '../../../types';
import { fileSuffix } from '../../../utils/dev';
import { cl, fetchData, getVmDetails, screenLog } from '../../../utils/general';
import { trackEvent, trackPageView } from '../../../utils/tracking';
import ContentBox from '../../common/ContentBox';
import ScreenTitle from '../../common/ScreenTitle';
import ScreenWrapper from '../../common/ScreenWrapper';
import { decryptOrderData, getNextStepCT5 } from './helpers';
import AdsComponent from '../../AdSenseComponent';

// wait screen is shown when we are waiting for the response from the server

const WaitScreen = (props: ScreenWithTimerProps) => {
    const { onError, onCancel, onTimerTimeoutHandler } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();
    const timerHook = useTimer(WAIT_TIMER);

    const vm = useRecoilValue<VendingMachine>(vmState);
    const [order, setOrder] = useRecoilState<Order>(orderState);
    const [isOrderFinished, setIsOrderFinished] = useState<boolean>(false);

    const { isCT5, isCT10 } = getVmDetails(vm);
    const isAds = vm.ads && JSON.parse(vm.ads);

    // after payment for CT1 flow is done we show this screen
    // and wait for the response from the server to define which screen to show next
    // if the response is success we show "success screen"
    // if the response is fail we show "fail screen"
    // success means check if the order is completed
    // `devise_status` should change to 4 - means food, snack, coffee,.. was prepared and provided to the customer
    // while `status` should stay 3.
    // if `status` changes to 4 - means that order wasn't completed and we should refund the money

    // logic
    // 1. start timer and check order status every 1 second
    // 2. if order status is 3 and device_status is 4 - show success screen
    // 3. if order status is 4 and device_status is 4 - show refund screen
    // 4. all other cases - show fail screen ? // TODO - validate
    // 5. if timer is 0 - show fail screen? // TODO - validate

    const getOrderInfo = (orderId: string) => {
        cl('kmg - getOrderInfo', orderId);
        if (!orderId || orderId === '' || orderId === 'null') {
            cl('kmg - 48 - no orderId');
            return;
        }

        const completedResponseFile = `/dev/order_completed${fileSuffix({
            isCT5,
            isCT10,
        })}.json`;

        const devUrl =
            timerHook.time > 33
                ? `/dev/order_precompleted${isCT5 ? '_ct5' : ''}.json?t=${
                      timerHook.time
                  }`
                : completedResponseFile;

        const orderInfoUrl = isDev
            ? devUrl
            : `${API.ORDER}?id=${orderId}&t=${timerHook.time}`;

        try {
            fetchData(
                orderInfoUrl,
                (orderInfo: Order) => {
                    const { status, device_status, id } = orderInfo;

                    const {
                        isOrderCompleted,
                        isOrderRefunded,
                        isOrderPaymentFailed,
                        isPaymentSuccessful,
                    } = decryptOrderData(status, device_status);

                    const isCT10FlowCompleted = isCT10 && isPaymentSuccessful;

                    let logMessage =
                        // 'kmg - 83 - order not completed ' +
                        `s: ${orderInfo.status} ds: ${orderInfo.device_status}`;
                    let navigatePath = '';

                    if (isOrderCompleted) {
                        logMessage = 'kmg - 87 - order completed';
                    } else if (isOrderRefunded) {
                        logMessage = 'kmg - 89 - order refunded';
                        navigatePath = `/${id}/refund`;
                    } else if (isOrderPaymentFailed) {
                        logMessage = 'kmg - 91 - order cancelled';
                        navigatePath = `/${id}/failed`;
                    } else if (isCT10FlowCompleted) {
                        logMessage = 'kmg - 95 - CT10 flow completed';
                        navigatePath = `/${id}/success`;
                    }

                    if (isCT5) {
                        cl('isCT5');
                        const step = getNextStepCT5(status, device_status, id);
                        logMessage = step.logMessage;
                        navigatePath = step.navigatePath;

                        cl('∑ CT5:', logMessage, navigatePath);
                    }

                    if (
                        isOrderCompleted ||
                        isOrderRefunded ||
                        isOrderPaymentFailed ||
                        isCT10FlowCompleted
                    ) {
                        setOrder(orderInfo);
                        setIsOrderFinished(true);
                    }

                    if (logMessage) {
                        cl(logMessage);
                    }
                    if (navigatePath) {
                        navigate(navigatePath, { replace: true });
                    }
                },
                () => {
                    onError && onError('Error loading order info - WaitScreen');
                }
            );
        } catch (e: any) {
            onError && onError('Error loading order info');
            cl('Error:', e.message);
        }
    };

    useEffect(() => {
        trackPageView('wait screen', { orderId: order.id, ...order });
        screenLog('WaitScreen');
        timerHook.start();
        cl('render WaitScreen', order.id);

        return () => {
            timerHook.reset();
        };
    }, []);

    useEffect(() => {
        if (timerHook.time <= 0) {
            // TODO - show fail screen
            cl('kmg - 99', 'show fail screen');
            onTimerTimeoutHandler && onTimerTimeoutHandler();
            navigate(`/${order.id}/failed`, { replace: true });
        } else {
            if (!isOrderFinished) {
                getOrderInfo(`${order.id}`);
            } else {
                trackEvent('order completed', { orderId: order.id, ...order });
                navigate(`/${order.id}/success`, { replace: true });
            }
        }
    }, [timerHook.time]);

    return (
        <ScreenWrapper>
            <ScreenTitle>{t('Wait')}</ScreenTitle>
            <ContentBox>
                <object id="vm" data="/vm.html" />
            </ContentBox>
            {isAds?.wait === 1 && <AdsComponent namePageClass="wait_page" />}
        </ScreenWrapper>
    );
};

export default WaitScreen;
